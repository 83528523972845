<template>
  <div class="item">
    <div 
    :class="{ 'duration-timer': canEdit, 'duration-timer-closed': !canEdit || attendance?.end_datetime }"
    >
      <!-- <div class="item-title">Duração</div> -->
      <span>{{ this.timer }}</span> <Clock />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { normalizeProductNumber } from '@/utils/product-helper'
import moment from 'moment'
import Clock from '@/assets/icons/clock.svg'

export default {
  components: {
    Clock,
  },
  data() {
    return {
      timer: '00:00:00',
      interval: null,
    }
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      attendance: state => state.attendance.attendance,
    })
  },
  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    setTimer() {
      const setup = () => {
        if(!this.attendance) return;
        const startedDate = moment(this.attendance.created_at).toDate()
        const finalDate = this.attendance?.end_datetime ? moment(this.attendance.end_datetime).toDate() : new Date()
        const progressTime = finalDate - startedDate
        const seconds = parseInt(progressTime / 1000)
        const minutes = parseInt(seconds / 60)
        const hours = parseInt(minutes / 60)
        this.timer = `${normalizeProductNumber((hours % 24), 2)}:${normalizeProductNumber((minutes % 60), 2)}:${normalizeProductNumber((seconds % 60), 2)}`
      }
      this.canEdit ?
        (this.interval = setInterval(setup, 1000)) :
        setup()
    }
  },
  watch: {
    attendance() {
      this.attendance && this.setTimer();
    },
  }
}
</script>
<style lang="scss" scoped>
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    .duration-timer {
      background-color: var(--states-success-soft-green);
      padding: 8px 16px;
      border-radius: 100px;
      font-weight: 700;
      font-size: 14px;
      color: var(--type-active);
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;

      svg {
        width: 16px;
        height: 16px;
        stroke: var(--states-success);
      }
    }
    .duration-timer-closed {
      background-color: #d9dff2;
      padding: 8px 16px;
      border-radius: 100px;
      font-weight: 700;
      font-size: 14px;
      color: #505050;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;

      svg {
        width: 16px;
        height: 16px;
        stroke: #505050;
      }
    }
  }
  .item-title {
    font-weight: 400;
    font-size: 12px;
    color: var(--type-active);
  }
</style>
