<template>
  <div>
    <div
      v-can="'FpPron11'"
      class="history"
      @click="showMedicalRecordsModal"
    >
      <IconHistory class="iconHistory"/>
    </div>

    <MedicalRecordsModal
      :patientId="attendanceId"
      :attendanceId="attendanceId"
    />
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import MedicalRecordsModal from '@/views/Patients/MedicalRecordsModal.vue'
import IconHistory from '@/assets/icons/history.svg'

export default {
  props: {
    type: String,
    attendanceId: String,
    patientId: String,
  },
  components: {
    MedicalRecordsModal,
    IconHistory,
  },
  methods: {
    ...mapActions('attendance/history', ['handleHistoryVisibility']),
    showMedicalRecordsModal(ev) {
      ev.stopPropagation()
      this.type === 'sidebar' ?
        this.handleHistoryVisibility() :
        this.$bvModal.show('medical-records-modal')
    },
  }
}
</script>
<style lang="scss" scoped>
  .history {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: var(--blue-500);
    cursor: pointer;

    .iconHistory {
      width: 24px;
      height: 24px;
      transform: scale(0.75) !important;
      stroke: var(--blue-500) !important;
    }
  }
</style>
