<template>
  <div class="avatar-container" :style="{ width: size ?? '40px', height: size ?? '40px', margin: margin || '0' }">
    <div class="avatar-content">
      <b-avatar
        v-if="src"
        :size="size ?? '40px'"
        :src="`${src}?x=${moment()}`"
      />
      <!-- <div v-else class="initials" :style="{ width: size ?? '40px', height: size ?? '40px' }">
        TT
      </div> -->
      <Avatar v-else :style="{ width: size ?? '40px', height: size ?? '40px' }" />
    </div>
    <div
      v-if="status"
      :id="id"
      :class="{ 'avatar-status': true, [status ? status.toLowerCase() : '']: true }"
    />
    <b-tooltip :target="id" v-if="status" placement="bottom">
      {{ parseTooltip(status) }}
    </b-tooltip>
    <div v-if="hasCamera" class="camera-button" @click="$emit('open-camera')">
      <Camera class="camera" />
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
export default {
  props: {
    src: String,
    status: String,
    size: String,
    margin: String,
    hasCamera: Boolean
  },
  components: {
    Avatar: () => import('@/assets/icons/avatar.svg'),
    Camera: () => import('@/assets/icons/camera.svg')
  },
  data() {
    return {
      id: `avatar_status_${uuidv4()}`,
    }
  },
  methods: {
    parseTooltip(status) {
      switch (status) {
        case 'SCHEDULED':
          return 'Sem situação';
        case 'CONFIRMED':
          return 'Confirmado';
        case 'CONFIRMED_BY_PATIENT':
          return 'Confirmado pelo paciente';
        case 'ATTEND':
          return 'Compareceu';
        case 'FINISHED':
          return 'Finalizado';
        case 'MISSED':
          return 'Faltou';
        case 'CANCELLED':
          return 'Cancelado';
        case 'CANCELLED_BY_PATIENT':
          return 'Cancelado pelo paciente';
        case 'WAITING':
          return 'Aguardando atendimento';
        case 'HAPPENING':
          return 'Em atendimento médico';
        case 'EXAMINATING':
          return 'Em exames';
        case 'DILATING':
          return 'Dilatando';
        case 'PRE_FINISHED':
          return 'Pré-consulta finalizada';
        case 'IN_PRE_CONSULTATION':
          return 'Em pré-consulta'
        case 'ALL_EXAMS_FINISHED':
          return 'Todos os Exames Realizados'

        default:
          return status;
      }
    }
  },
}
</script>
<style lang="scss" scoped>
  .avatar-container {
    position: relative;
  }

  .avatar-content {
    display: inline-flex;
    overflow: hidden;
    border-radius: 50%;

    svg{
      height: 40px;
    }
  }

  .initials {
    background-color: #0B30B2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    color: white;
    font-size: '50%';
    padding: 0.5rem;
  }

  .camera-button {
    width: 24px;
    height: 24px;
    background-color: var(--blue-500);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;

    .camera {
      width: 12px;
      height: 12px;
      fill: var(--neutral-000);
    }
  }
  .avatar-status {
    width: 18px;
    height: 18px;
    position: absolute;
    border-radius: 100%;
    bottom: 0;
    right: 0;
    &.scheduled {
      background: #FFFFFF;
      border: 3px solid #A4B1DF;
    }
    &.confirmed {
      background: #046340;
      border: 3px solid #F2FFE6;
    }
    &.confirmed_by_patient {
      background: #046340;
      border: 3px solid #F2FFE6;
    }
    &.attend {
      background: #0B30B2;
      border: 3px solid #E6F5FF;
    }
    &.missed {
      background: #F63220;
      border: 3px solid #FFD6D2;
    }
    &.cancelled {
      background: #525C7A;
      border: 3px solid #E9EBF2;
    }
    &.cancelled_by_patient {
      background: #525C7A;
      border: 3px solid #E9EBF2;
    }
    &.finished {
      background: #304388;
      border: 3px solid #F8FDFF;
    }
    &.waiting {
      background: #FFCA00;
      border: 3px solid #FFF6D5;
    }
    &.happening {
      background: #FF6B00;
      border: 3px solid #FFF3E1;
    }
    &.examinating {
      background: #D94F91;
      border: 3px solid #FFE3F1;
    }
    &.dilating {
      background: #673270;
      border: 3px solid #FBF0FF;
    }
    &.pre_finished {
      background: #613F26;
      border: 3px solid #FFEFE4;
    }

    &.in_pre_consultation {
      background: #23acb1;
      border: 3px solid #FFEFE4;
    }

    &.all_exams_finished {
      background: #35b20c;
      border: 3px solid #FFEFE4;
    }
  }
</style>
