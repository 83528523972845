const priorityLevels = [
  {
    name: 'LOW',
    icon: 'PriorityLowIcon',
    class: 'low',
    label: 'Não urgente'
  },
  {
    name: 'NORMAL',
    icon: 'PriorityNormalIcon',
    class: 'normal'
  },
  {
    name: 'MEDIUM',
    icon: 'PriorityMediumIcon',
    class: 'medium',
    label: 'Pouco urgente'
  },
  {
    name: 'HIGH',
    icon: 'PriorityHighIcon',
    class: 'high',
    label: 'Urgência'
  },
  {
    name: 'VERY_HIGH',
    icon: 'PriorityVeryHighIcon',
    class: 'very-high',
    label: 'Emergência'
  }
]

export const getLabelPriority = priority => {
  return priorityLevels.find(level => level.name === priority)?.label
}
export const getIconPriority = priority => {
  if (!priority) {
    priority = 'NORMAL'
  }
  return priorityLevels.find(level => level.name === priority)?.icon
}
export const getClassPriority = priority => {
  if (!priority) {
    priority = 'NORMAL'
  }
  return priorityLevels.find(level => level.name === priority)?.class
}
